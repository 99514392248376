<template>
    <div>
    	<template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-row class="match-height">
          <b-col lg="12" md="12">
        
            <b-card title="Change Password">
              
              <b-alert v-model="showDismissibleAlert" variant="danger" class="mb-1">
                <div class="alert-body">
                  {{error_message}}
                </div>
              </b-alert>
              
              <b-form @submit="formSubmit">
                
                <b-row>
                  <b-col md="6">
                    <b-row>
                      <b-col md="12">
                        
                        <b-form-group label="New Password" class="required">
                          <b-input-group class="input-group-merge">
                            <b-form-input v-model="password" class="form-control-merge" :type="passwordFieldType" placeholder="New Password" autocomplete="off"/>
                            <b-input-group-append is-text>
                              <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>

                        <!-- <b-form-group label="New Password" class="required">
                          <b-form-input id="person_name" placeholder="New Password" v-model="password" autocomplete="off" class="form-control-merge" :type="passwordFieldType"/>
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-form-group> -->
                      </b-col>

                      <b-col md="12">
                        <!-- <b-form-group label="Confirm Password" class="required">
                          <b-form-input id="person_name" placeholder="Confirm Password" v-model="confirm_password" autocomplete="off" class="form-control-merge" :type="passwordFieldType"/>
                        </b-form-group> -->

                        <b-form-group label="Confirm Password" class="required">
                          <b-input-group class="input-group-merge">
                            <b-form-input v-model="confirm_password" class="form-control-merge" :type="passwordFieldTypeConfirm" placeholder="Confirm Password" autocomplete="off"/>
                            <b-input-group-append is-text>
                              <feather-icon class="cursor-pointer" :icon="passwordFieldTypeIconConfirm" @click="togglePasswordVisibilityConfirm" />
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                        
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="mt-1">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
                      Submit
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.go(-1)">
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              
              </b-form>
            </b-card>

          </b-col>
        </b-row>

    </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BInputGroupAppend, BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API, LOGOUT } from "../../../store/actions.type"
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, BFormRadio, BInputGroupAppend, BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      error_message:null,
      showDismissibleAlert:false,
      password:'',
      confirm_password:'',
      passwordFieldTypeConfirm: 'password',
      passwordFieldTypeIconConfirm: 'EyeIcon'
    }
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods : {
    togglePasswordVisibilityConfirm(){
      this.passwordFieldTypeIconConfirm = this.passwordFieldTypeConfirm === 'password' ? 'EyeOffIcon' : 'EyeIcon';
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password';
    },
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             password         : this.password,
             confirm_password : this.confirm_password,
             user_id          : this.$store.getters.currentUser._id
           },
           api: '/api/change-password'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                // this.successAlert().then((result) => {
                this.customAlert2('Password Changed Successfully.', '', 'success', 'center', true).then((result) => {
                    // this.$router.go(-1);
                    this.logout();
                });
                
            }
        });      
    },
    logout() {      
      var role = this.$store.getters.currentUser.role;
      var id = this.$store.getters.currentUser._id;

      if (role == 'super_admin') {
        var routeName = 'admin-login'
      }else{
        var routeName = 'login'
      }

      this.$store.dispatch(LOGOUT, {
          data: {
          },
          api: '/api/logout?id='+id
      })
      .then(() => {
          if (!this.$store.getters.containsErrors) {
              this.$router.push({ name: routeName })
          }
      });
    },

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Change Password',
        active:true
      }];
      return item;
    }

  }
}
</script>

